'use client';

import { useTranslation as useReactI18nextTranslation } from 'react-i18next';

/**
 * useTranslation hook provides the translation function and i18n instance.
 */
export const useTranslation = () => {
    const { t, i18n } = useReactI18nextTranslation();
    return { t, i18n };
};