'use client';

import React from 'react';
import { Button, ButtonGroup } from "@nextui-org/react";
import { useRouter } from 'next/navigation';

interface MenuItem {
    icon?: React.ReactNode;
    href?: string;
    onPress?: () => void;
    label: string;
    isActive?: boolean;
}

interface TopMenuProps {
    menuItems: MenuItem[];
    className: string;
}

const TopMenu: React.FC<TopMenuProps> = ({ menuItems, className }) => {
    const router = useRouter();

    const handleItemClick = (item: MenuItem) => {
        if (item.href) {
            router.push(item.href);
        } else if (item.onPress) {
            item.onPress();
        }
    };

    return (
        <ButtonGroup
            variant="flat"
            className={`rounded-xs ${className}`}
        >
            {menuItems.map((item, index) => (
                <Button
                    key={index}
                    startContent={item.icon}
                    className={`${item.isActive ? 'bg-primary text-white' : 'bg-primary text-white'}`}
                    onClick={() => handleItemClick(item)}
                >
                    {item.label}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default TopMenu;