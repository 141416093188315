import React from 'react';
import { Link } from '@nextui-org/react';
import Image from 'next/image';
import { cn } from '@/utils/cn';

interface LogoProps {
    size?: number;
    showText?: boolean;
    className?: string;
    variant?: 'default' | 'transparent' | 'dark';
}

const Logo: React.FC<LogoProps> = ({ 
    size = 60, 
    showText = true, 
    className = '',
    variant = 'default'
}) => {
    return (
        <Link href="/" className={className}>
            <div className="flex items-center gap-3">
                <div className="relative w-8 h-8 sm:w-12 sm:h-12">
                    <Image
                        src="/images/logo_square.svg"
                        alt="Right2Hope Logo"
                        fill
                        className="object-contain"
                        priority
                    />
                </div>
                {showText && (
                    <span className={cn(
                        "mt-1 text-xl font-semibold",
                        variant === 'dark' ? "text-white" : "text-primary"
                    )}>
                        Right2Hope
                    </span>
                )}
            </div>
        </Link>
    );
};

export default Logo;